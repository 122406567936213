/* Preview item */

.tag {
	padding: 0 10px 0 20px;
}

.tag > article {
	padding: 15px 0 30px 0;
}

.tag > article > a {
	padding: 10px 30px;
	transition: 0.1s linear all;
	cursor: pointer;
	border-radius: 10px;
	display: block;
}

.tag > article > a:focus,
.tag > article > a:hover {
	transform: translateY(0);
}

.tag > article > a:hover {
	background-color: #2f2a2a;
}

.tag > article > a > div {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 30px;
}

.tag > article > a > div > time {
	line-height: 31px;
	font-weight: 300;
}

.tag > article > a > p {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.new {
	color: var(--gold);
	font-weight: bold;
}

@media (max-width: 553px) {
	.tag > article > a > div {
		flex-direction: column;
		align-items: start;
	}

	.tag {
		padding: 0 10px 0 10px;
	}

	.tag > article > a {
		padding: 10px 20px;
	}
}
