.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container > div {
	max-width: 400px;
	width: 100%;
}

.container > div > h3 {
	margin-bottom: 30px;
	text-align: center;
}

.container > div > form {
	display: flex;
	flex-direction: column;
}

.container > div > form input,
.btn > button {
	border-radius: 10px;
	color: var(--white);
	font-size: inherit;
}

.container > div > form > input,
.btn > button,
.eye {
	padding: 15px 20px;
}

.container > div > form > input {
	background: var(--agrey);
	margin-bottom: 20px;
}

.input {
	position: relative;
	margin-bottom: 20px;
}

.btn {
	position: relative;
	margin-top: 10px;
}

.input > input {
	width: 100%;
	padding: 15px 63px 15px 20px;
	background: var(--agrey);
}

.input > input[type='email'] {
	width: 100%;
	padding: 15px 20px;
	background: var(--agrey);
}

.input > label,
.btn > label {
	position: absolute;
	cursor: pointer;
	transform: translateY(-50%);
	left: 105%;
	top: 50%;
	padding: 10px;
	background-color: var(--red);
	border-radius: 10px;
	width: max-content;
	max-width: 100%;
}

.input > label:after,
.btn > label:after {
	content: '';
	border: 10px solid transparent;
	border-right: 10px solid var(--red);
	left: -18px;
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
}

.eye {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
}

.eye svg {
	width: 23px;
	height: 23px;
	fill: var(--opwhite);
}

.btn > button {
	background-color: var(--dark-red);
	transition: 0.1s linear all;
	width: 100%;
	min-height: 53px;
}

.btn > button:hover {
	background-color: var(--redh);
}

.btn > button:disabled {
	cursor: not-allowed;
	opacity: 0.7;
}

.btn > button:disabled:hover {
	background-color: var(--dark-red);
}

@media (max-width: 916px) {
	.input > label,
	.btn > label {
		transform: none;
		left: 0;
		top: auto;
		bottom: 60px;
		
	}

	.input > label:after,
	.btn > label:after {
		transform: none;
		top: auto;
		left: 10px;
		bottom: -9px;

		border: 10px solid transparent;
		border-right: 10px solid var(--red);
	}
}
