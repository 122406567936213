.row {
	display: flex;
	align-items: center;
	margin: 0 -20px;
	flex-wrap: wrap;
}

.forty,
.sixty {
	padding: 0 20px;
}

.forty {
	width: 40%;
	position: relative;
}

.forty ul li:not(:last-child) {
	margin-bottom: 30px;
}

.sixty {
	width: 60%;
}

.sixty h3 {
	margin-bottom: 30px;
}

.sixty code {
	line-height: 25px;
	word-break: break-all;
}

@media (max-width: 856px) {
	.forty,
	.sixty {
		width: 100%;
	}

	.forty {
		margin-bottom: 50px;
	}
}

@media (max-width: 553px) {
	.sixty code {
		font-size: 16px;
		line-height: 24px;
	}
}
