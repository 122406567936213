.tag {
	margin-bottom: 60px;
}

.tag > article > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.tag > article > div > time {
	line-height: 31px;
	font-weight: 300;
}

@media (max-width: 553px) {
	.tag > article > div {
		align-items: start;
		flex-direction: column;
	}

	.tag {
		margin-bottom: 50px;
	}
}
