/* Common */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

:root {
	--white: #fff;
	--opwhite: rgba(255, 255, 255, 0.5);
	--black: #1b1818;
	--opblack: rgba(0, 0, 0, 0.25);
	--grey: #c4c4c4;
	--dgrey: #403131;
	--dark-red: #951234;
	--green: #2bb449;
	--red: #ee3024;
	--agrey: #463d3d;
	--redh: #7d102c;
	--gold: #ffd700;
	--orange: #e99e0d;
}

html{
	
	background-color: var(--black);
}
body {
	font-family: 'Open Sans', sans-serif;
	color: var(--white);
	font-size: 17px;
	
	
	background-image: url('../../img/bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}


a {
	color: inherit;
	display: inline-block;

	transition-property: transform;
	transition-duration: 0.3s;
	transform: translateZ(0);
}

a:focus,
a:hover {
	transform: translateY(-3px);
}

h3 {
	font-weight: 600;
	font-size: 20px;
	line-height: 37px;
}
.title{
	font-weight: 700;
   font-size: 30px;
   line-height: 41px;
   text-align: center;
   max-width: 621px;
	margin-bottom: 20px;
}
.container {
	max-width: 1380px;
	margin: 0 auto;
	padding: 0 20px;
}

.container__body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main {
	flex: 1 0 auto;
	padding-top: 130px;
	padding-bottom: 70px;
}

.common__body {
	box-shadow: -4px 4px 10px 6px var(--opblack);
	border-radius: 10px;
	padding: 25px 60px 40px 60px;
	background-color: var(--black);
}

.common__text {
	font-size: 20px;
	line-height: 37px;
	word-wrap: break-word;
}
@media (max-width:1200px) {
	
	body.header__open {
		overflow: hidden;
	}
}
@media (max-width: 553px) {
	.common__text {
		font-size: 17px;
		line-height: 34px;
	}

	h3 {
		font-size: 18px;
	}

	.common__body {
		padding: 20px 25px 30px 25px;
	}
	.title{
		margin-bottom: 0px;
	}

	
}
