/* Title */
.title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 130px;
}

.title__body {
	text-align: center;
	max-width: 725px;
}

.title__body > h1 {
	font-weight: bold;
	font-size: 30px;
	line-height: 41px;
	margin-bottom: 20px;
}

.title__body > p {
	font-size: 20px;
	line-height: 27px;
}

.title__body > p > a {
	text-decoration: underline;
}

/* Slider */
.preview {
	max-width: 640px;
}

.preview__title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.preview__title a {
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' stroke-width='0' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' height='23px' width='23px' fill='%23fff'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'%3E%3C/path%3E%3Cpath d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'%3E%3C/path%3E%3C/svg%3E");
	padding-right: 33px;
	background-position-x: right;
	background-position-y: center;
	line-height: 31px;
	font-weight: 300;
}

.preview__body {
	box-shadow: -4px 4px 10px 6px var(--opblack);
	background-color: var(--black);
	border-radius: 10px;
	padding: 0 10px;
}

.preview__body ul {
	height: 206px;
	overflow: auto;

	/* Custom firefox scrollbar */
	scrollbar-color: var(--grey) var(--dgrey);
	scrollbar-width: thin;
	position: relative;
}

.preview__body ul li:not(:last-child) article {
	border-bottom: 1px solid var(--opwhite);
}

/* Custom webkit scrollbar */
.preview__body ul::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: var(--dgrey);
}

.preview__body ul::-webkit-scrollbar-thumb {
	background-color: var(--grey);
	border-radius: 5px;
}

.preview__body ul::-webkit-scrollbar-thumb:hover {
	background-color: var(--opwhite);
}

@media (max-width: 553px) {
	.preview__title {
		flex-direction: column;
		align-items: start;
	}

	.preview__body ul {
		height: 231px;
	}

	.title__body > p {
		font-size: 17px;
		line-height: 24px;
	}
}
