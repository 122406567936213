/* Loader */
.loader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.loader div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid var(--white);
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: var(--white) transparent transparent transparent;
}
.loader div:nth-child(1) {
	animation-delay: -0.45s;
}
.loader div:nth-child(2) {
	animation-delay: -0.3s;
}
.loader div:nth-child(3) {
	animation-delay: -0.15s;
}

.loader.active {
	width: 40px;
	height: 40px;
}

.loader.active div {
	width: 32px;
	height: 32px;
	margin: 4px;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
