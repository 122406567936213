.tag {
	display: flex;
	padding: 10px 0;
}

.tag div:first-child {
	width: 80%;
}

.tag div:last-child {
	width: 20%;
}

.ok,
.err {
	text-transform: uppercase;
	border-radius: 37px;
	padding: 0 8px;
	display: inline;
}

.ok {
	background-color: var(--green);
}

.err {
	background-color: var(--red);
}

@media (max-width: 553px) {
	.tag > div {
		font-size: 14px;
		line-height: 31px;
	}
}
