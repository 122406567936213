.cnt{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
}




.rates{
   padding-top: 70px;
   flex: 1 1 auto;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.rate.blur{
   filter: blur(7.5px);
   opacity: 0.8;
   pointer-events: none;
}
.rate.mainRate{
   border: 5px solid var(--orange);
   max-width: 410px;

}
.rate__p{
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
}
.rate__p img{
   margin-right: 10px;
   /* width: 23px;
   height: 23px;
   object-fit: contain; */
}
.rate__p:not(:last-child){
   padding-bottom: 20px;
}
.rate{
   
   max-width: 400px;
   width: 100%;
   
}

.rate__title{
   text-align: center;
}
.rate__description{
   padding: 30px 0;
}

.rate__btn{
   font-size: 17px;
   line-height: 23px;
   height: 53px;
   background: var(--dark-red);
   border-radius: 10px;
   transition: background-color 0.1s linear;
   width: 100%;
   color: var(--white);
}

.rate__btn:hover{
   background-color: var(--redh);
}
.link{
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   transform: translate(0,0)!important;
}


@media (max-width:1300px) {
   .rates{
      flex-direction: column;
   }
   .rate:not(:last-child){
      margin-bottom: 20px;
   }
   .rate:nth-child(1){
      order:1;
   }
   .rate:nth-child(2){
      order:0;
   }
   .rate:nth-child(3){
      order:2;
   }
}

