.container {
	max-width: 1110px;
	margin: 0 auto;
}

.title {
	display: flex;
	margin-bottom: 30px;
}

.title h3:first-child {
	width: 80%;
}

.title h3:last-child {
	width: 20%;
}

.container ul li:not(:last-child) {
	border-bottom: 1px solid var(--opwhite);
}
