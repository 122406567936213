.mailing > div {
	display: flex;
	align-items: center;
}

.apikey {
	display: flex;
	align-items: center;
}

.apikey > p {
	padding-right: 6px;
}

.apikey > button {
	background-color: transparent;
}

.apikey > button > svg {
	color: var(--opwhite);
	font-size: 20px;
}

/*Custom checkbox */
#checkbox {
	display: none;
}

.custom__checkbox {
	margin-right: 10px;
}

#checkbox + .custom__checkbox {
	outline: 0;
	display: block;
	width: 50px;
	height: 25px;
	position: relative;
	cursor: pointer;
	user-select: none;
}
#checkbox + .custom__checkbox:after,
#checkbox + .custom__checkbox:before {
	position: relative;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
}
#checkbox + .custom__checkbox:after {
	left: 0;
}
#checkbox + .custom__checkbox:before {
	display: none;
}
#checkbox:checked + .custom__checkbox:after {
	left: 50%;
}

#checkbox + .custom__checkbox {
	background: var(--red);
	border-radius: 25px;
	transition: all 0.4s ease;
	border: 1px solid #e8eae9;
}
#checkbox + .custom__checkbox:after {
	border-radius: 25px;
	background: #fbfbfb;
	transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
		padding 0.3s ease, margin 0.3s ease;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
#checkbox + .custom__checkbox:hover:after {
	will-change: padding;
}
#checkbox + .custom__checkbox:active {
	box-shadow: inset 0 0 0 25px #e8eae9;
}
#checkbox + .custom__checkbox:active:after {
	padding-right: 9px;
}
#checkbox:checked + .custom__checkbox {
	background: var(--green);
}
#checkbox:checked + .custom__checkbox:active {
	box-shadow: none;
}
#checkbox:checked + .custom__checkbox:active:after {
	margin-left: -9px;
}

@media (max-width: 553px) {
	.mailing p {
		font-size: 14px;
	}
}
