/* Header */

/* Wrap */
.tag {
	padding-top: 60px;
}

.body {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* Logo */
.logo a {
	font-weight: bold;
	font-size: 25px;
	line-height: 34px;
}

/* Menu */
.menu{
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1 auto;
}
.menu ul li {
	padding: 0 20px;
}

/* Auth */
.auth ul li {
	padding: 0 12.5px;
}

.auth ul li a {
	padding-left: 33px;
}

.signin a,
.signup a,
.signout a {
	background-repeat: no-repeat;
}

.signin a {
	background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' stroke-width='0' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg' height='23px' width='23px' fill='%23fff'%3E%3Cdefs%3E%3C/defs%3E%3Cpath d='M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 0 1 520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 0 1 270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 0 1 0 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z'%3E%3C/path%3E%3C/svg%3E");
}

.signup a {
	background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' stroke-width='0' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg' height='23px' width='23px' fill='%23fff'%3E%3Cpath d='M678.3 642.4c24.2-13 51.9-20.4 81.4-20.4h.1c3 0 4.4-3.6 2.2-5.6a371.67 371.67 0 0 0-103.7-65.8c-.4-.2-.8-.3-1.2-.5C719.2 505 759.6 431.7 759.6 349c0-137-110.8-248-247.5-248S264.7 212 264.7 349c0 82.7 40.4 156 102.6 201.1-.4.2-.8.3-1.2.5-44.7 18.9-84.8 46-119.3 80.6a373.42 373.42 0 0 0-80.4 119.5A373.6 373.6 0 0 0 137 888.8a8 8 0 0 0 8 8.2h59.9c4.3 0 7.9-3.5 8-7.8 2-77.2 32.9-149.5 87.6-204.3C357 628.2 432.2 597 512.2 597c56.7 0 111.1 15.7 158 45.1a8.1 8.1 0 0 0 8.1.3zM512.2 521c-45.8 0-88.9-17.9-121.4-50.4A171.2 171.2 0 0 1 340.5 349c0-45.9 17.9-89.1 50.3-121.6S466.3 177 512.2 177s88.9 17.9 121.4 50.4A171.2 171.2 0 0 1 683.9 349c0 45.9-17.9 89.1-50.3 121.6C601.1 503.1 558 521 512.2 521zM880 759h-84v-84c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v84h-84c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h84v84c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-84h84c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z'%3E%3C/path%3E%3C/svg%3E");
}
.signout a {
	background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' stroke-width='0' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg' fill='%23fff' height='23px' width='23px'%3E%3Cpath d='M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z'%3E%3C/path%3E%3C/svg%3E");
}

/* Common */
.menu ul,
.auth ul {
	display: flex;
}

.auth ul li a,
.menu ul li a {
	line-height: 23px;
}

.menu ul li:first-child,
.auth ul li:first-child {
	padding-left: 0;
}

.menu ul li:last-child,
.auth ul li:last-child {
	padding-right: 0;
}

.burger {
	display: none;
}

.overlay {
	opacity: 0;
	visibility: hidden;
}

.rates {
	color: var(--orange);
	font-weight: 600;
}
.menucnt{
	flex: 1 1 auto;
}
.menucnt__container{
	display:flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
@media (max-width: 1200px) {
	.overlay {
		position: fixed;
		display: block;
		background-color: var(--opblack);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transition: 0.3s all ease;
		cursor: pointer;
		z-index: 1;
	}

	.tag.open .overlay {
		opacity: 1;
		visibility: visible;
	}

	.menu{
		flex: none;
	}
	.menucnt{
		padding-top: 124px;
		z-index: 2;
		position: fixed;
		bottom: 0;
		opacity: 0;
		visibility: hidden;
		left: -250px;
		transition: 0.3s all ease;
		top: 0;
		background-color: var(--agrey);
		max-width: 280px;
		width: 100%;
	}
	.menucnt__container {
		overflow: auto;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		height: 100%;
	}

	

	.tag.open .menucnt,
	.tag.open .menucnt {
		left: 0;
		opacity: 1;
		visibility: visible;
	}

	.menu ul,
	.auth ul {
		flex-direction: column;
	}

	.menu ul {
		padding: 0 10px 30px 10px;
	}

	.auth ul {
		padding: 0 0 30px 10px;
	}

	.menu ul li,
	.auth ul li {
		padding: 0;
		margin-bottom: 10px;
	}

	.menu ul li:last-child,
	.auth ul li:last-child {
		margin-bottom: 0;
	}

	.burger {
		display: block;
		z-index: 3;
	}

	.burger {
		width: 32.5px;
		height: 25px;
		position: relative;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
	}

	.burger span {
		display: block;
		position: absolute;
		height: 4px;
		width: 100%;
		background: var(--white);
		border-radius: 4px;
		opacity: 1;
		visibility: visible;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;
	}

	.burger span:nth-child(1) {
		top: 0px;
	}

	.burger span:nth-child(2) {
		top: 10.5px;
	}

	.burger span:nth-child(3) {
		top: 21px;
	}

	.tag.open .burger span:nth-child(1) {
		top: 10.5px;
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-o-transform: rotate(135deg);
		transform: rotate(135deg);
	}

	.tag.open .burger span:nth-child(2) {
		opacity: 0;
		visibility: hidden;
		left: 60px;
	}

	.tag.open .burger span:nth-child(3) {
		top: 10.5px;
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
}

@media (max-width: 553px) {
	.logo a {
		font-size: 18px;
	}
}
